import React from 'react';

// Components
import QuestionnaireContainer from '../views/Questionnaire/QuestionnaireContainer';
import BookingNumber from '../views/BookingNumber';
import ClaimAmount from '../views/ClaimAmount';
import LegalGuardianStep from '../views/LegalGuardian/LegalGuardianStep';
import ThankYou from '../views/ClaimPage/ThankYou';
import PageTransition from '../components/PageTransition';
import DirectFeedbackUploadStep from '../views/DirectFeedbackUpload/DirectFeedbackUploadStep';
import SharablePageWrapper from '../views/SharablePageWrapper';
import AdditionalCostAlternativeFlight from '../views/AdditionalCostAlternativeFlight';
import translations from '../translations/main.json';
import Login from '../views/Login';

// Translations
import routes from '../messages/routes';
import { PageType } from '../views/Passengers/PassengerContainer';
import PassengerStep from '../views/Passengers/PassengerStep';
import NotFoundPage from '../pages/404';

const ClaimPage = () => (
  <PageTransition basepath={routes.claim} showStepper={false} hideMenu={true}>
    <SharablePageWrapper path="/" pageType={PageType.Claim} />
    <SharablePageWrapper
      path=":claimNumber"
      pageType={PageType.Claim}
      redirect={false}
    >
      <QuestionnaireContainer path={'/'} pageType={PageType.Claim} />
      <SharablePageWrapper
        path={translations['cc4.seo.route.passenger']}
        pageType={PageType.Claim}
      />
      <SharablePageWrapper
        path={translations['cc4.seo.route.passenger'] + '/:passengerId'}
        redirect={false}
        pageType={PageType.Claim}
      >
        <PassengerStep pageType={PageType.Claim} path="/" />
        <LegalGuardianStep
          pageType={PageType.Claim}
          path={
            translations['cc4.seo.route.legal-guardian-info'] +
            '/:guardianIndex'
          }
        />
      </SharablePageWrapper>
      <DirectFeedbackUploadStep
        pageType={PageType.Claim}
        path={'/:documentTypeRouteParam'}
      />
      <AdditionalCostAlternativeFlight
        pageType={PageType.Claim}
        path={translations['cc4.seo.route.upload-additional-costs']}
      />
      <ClaimAmount
        pageType={PageType.Claim}
        path={translations['cc4.seo.route.claim-amount']}
      />
      <BookingNumber
        pageType={PageType.Claim}
        path={translations['cc4.seo.route.booking-reference']}
      />
      <Login path={translations['cc4.seo.route.login']} />
      <ThankYou path={translations['cc4.seo.route.passenger-thank-you']} />
      <NotFoundPage default />
    </SharablePageWrapper>
  </PageTransition>
);

export default ClaimPage;
